var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(!_vm.userManager.checkRole('mkt', true))?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Nguồn khách","items":[{ value: '', text: 'Tất cả' }].concat(_vm.availableSources),"hide-details":""},model:{value:(_vm.filterSource),callback:function ($$v) {_vm.filterSource=$$v},expression:"filterSource"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.timeModes,"label":"Chế độ thời gian"},model:{value:(_vm.timeMode),callback:function ($$v) {_vm.timeMode=$$v},expression:"timeMode"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Chọn tháng","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"type":"month"},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}})],1)],1)],1)],1),(_vm.currentDate)?_c('div',{staticClass:"mt-4"},[(_vm.totalItem)?_c('v-simple-table',{staticClass:"report-total blue lighten-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Tổng vận chuyển")]),_c('th',[_vm._v("Tổng lương")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.fee_transport)))]),_c('td',[_vm._v(_vm._s(_vm._f("money")(_vm.totalItem.salary_ds)))])])])]},proxy:true}],null,false,3988695911)}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-4 report-details",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","items-per-page":50,"footer-props":{
          'items-per-page-options': [50, 100, 150, 200, 250],
        },"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.customer_codes",fn:function(ref){
        var item = ref.item;
return [(item.customer_codes)?_vm._l((item.customer_codes.split(',')),function(code){return _c('v-chip',{key:code,staticClass:"mr-2"},[_vm._v(" "+_vm._s(code)+" ")])}):_vm._e()]}},{key:"item.fee_transport",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.fee_transport))+" ")]}},{key:"item.salary_ds",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.salary_ds))+" ")]}},{key:"item.customer_created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.customer && item.customer.created_at))+" ")]}},{key:"item.liquidation_slip_approve_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.liquidation_slip.approve_time))+" ")]}},{key:"item.customer_source",fn:function(ref){
        var item = ref.item;
return [(item.customer)?_c('v-chip',[_vm._v(_vm._s(_vm.availableSourceMap[item.customer.source]))]):_vm._e()]}},{key:"item.sale_name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.sale_user && item.sale_user.name)+" ")]}},{key:"item.customer_name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer && item.customer.name)+" ")]}}],null,false,3284418348)})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }