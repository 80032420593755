



























































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      items: [],
      totalItem: null,
      filterSource: "",
      currentDate: "",
      loading: false,
      headers: [
        { text: "Tên khách hàng", value: "customer_name" },
        { text: "Mã khách hàng", value: "customer_codes" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: "Kinh doanh", value: "sale_name" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Lương", value: "salary_ds" },
        { text: "Ngày về VN", value: "liquidation_slip_approve_time" },
        { text: "Ngày tạo khách hàng", value: "customer_created_at" },
        { text: "Mã đơn hàng", value: "package_id" },
        { text: "Nguồn khách", value: "customer_source" },
      ],
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
      availableSourceMap: {
        k: "Khác",
        mkt: "Marketing",
        gt: "Giới thiệu",
        tk: "Tìm kiếm",
      },
    };
  },
  computed: {
    availableSources() {
      const items = [];
      for (const k in this.availableSourceMap) {
        items.push({
          text: this.availableSourceMap[k],
          value: k,
        });
      }
      return items;
    },
  },
  watch: {
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
    filterSource() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentDate) {
        return;
      }
      const m = moment(this.currentDate);

      this.loading = true;
      const options: any = {
        extra: {
          liquidation_slip_approve_time: {
            from: m.startOf(this.timeMode).unix(),
            to: m.endOf(this.timeMode).unix(),
          },
          new_customer: true,
          customer_source: this.filterSource,
        },
        limit: -1,
        report: true,
      };
      let { items } = await apiClient.packageGetAll(options);
      items = items.map((item) => {
        item.salary_ds = item.fee_transport * 0.1;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.fee_transport += item.fee_transport;
          totalItem.salary_ds += item.salary_ds;
          return totalItem;
        },
        {
          fee_transport: 0,
          salary_ds: 0,
        }
      );

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
  },
});
